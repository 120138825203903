import Vue from 'vue'
import router from '../_main/router'
import localData from '../mixins/localData'
// import features from '../_main/features'
import _ from 'lodash'
import { getCookieValue } from '../_main/_auth-header'
// import {CXI_constants} from '../_main/constants.js'

function defaultData() {
  return {
    currentUser: {
      id: null,
      name: null,
      email: null,
      roles: {},
      ieqAdmin: false,
      logoUrl: null,
    },
    currentCustomer$: {},    
    error: '',
  }
}

const auth = new Vue({
  name: 'auth',
  router: router,
  mixins: [localData],
  data() {
    return defaultData()
  },
  // created() {
  //   this.TRIAL_USER_TYPE = CXI_constants.TRIAL_USER_TYPE;
  // },
  computed: {
    isSignedIn() {
      //return !_.isEmpty(this.currentUser)
      return getCookieValue('auth_token') ? true : false
    },
    isTrialUser(){
      return parseInt( this.currentCustomer$.customer_type ) == 4 ? true : false
    },
    roles() {
      return _.get(this, 'currentUser.roles', {})
    },
    is() {
      if (!this.isSignedIn) {
        return false
      }
      return {
        ieqAdmin: _.get(this, 'currentUser.ieqAdmin'),
        admin: _.get(this, 'currentUser.customerAdmin'),
      }
    },
    can() {
      if (!this.isSignedIn) {
        return false
      }
      return {
        viewCatalog:
          (this.is.ieqAdmin || _.get(this, 'currentUser.features.catalog')) && !this.isTrialUser,
        viewLibrary:
          this.is.ieqAdmin || _.get(this, 'currentUser.features.library'),
        viewMaintenance:
          this.is.ieqAdmin || _.get(this, 'currentUser.features.maintenance'),
        order: this.is.ieqAdmin || _.get(this, 'currentUser.features.order'),

        editCatalog: this.is.ieqAdmin,
        editLibrary:
          this.is.ieqAdmin || _.get(this, 'currentUser.features.catalog'),
        editMaintenance:
          this.is.ieqAdmin || _.get(this, 'currentUser.features.maintenance'),
      }
    },
    currentCustomer: {
      get() {
        return this.currentCustomer$ || {}
      },
      set(customer) {
        if (customer == null) {
          console.log('ATTEMPT TO SET CUSTOMER TO NULL; ABORTED')
          // TODO: log custom AppSignal error
          this.currentCustomer$ = {}
        } else {
          this.currentCustomer$ = customer
        }
      },
    },
  },
  methods: {
    logIn(loginDATA) {
      this.clearError()
      /* this.$http
        .post(this.$routes.user_session_path({ format: 'json' }), { */
      this.$http
        .post('/auth/token/login/', loginDATA)
        .then((response) => {
          _.assign(this, response.data)
          //let auth_token = response.data.currentUser.token
          let auth_token = response.data.auth_token
          // let auth_token = response.data.access
          // let refresh_token = response.data.refresh
          this.$cookies.set('auth_token', auth_token, '30d')
          // this.$cookies.set('refresh_token', refresh_token, '30d')
          let destination = _.get(router.app.$route, 'query.redirectFrom', '/')
          console.log(destination)
          //this.$router.push(`${destination}`)
          window.location.href = destination
        })
        .catch((error) => {
          if (_.get(error, 'response.data.error.message')) {
            this.error = _.get(error, 'response.data.error.message')
          } else {
            this.error = 'Login failed'
          }
        })
    },
    logOut() { 
      this.$http.post('/auth/token/logout', {})
        .then((response) => {
          console.log('logout response ', response)
          this.$cookies.remove('auth_token')
          _.assign(this, defaultData())
          localStorage.removeItem('switchCustomerId')
          localStorage.removeItem('switchedCustomerInfo')
          // router.push({ name: "login" })
          let destination = _.get(
            router.app.$route,
            'query.redirectFrom',
            '/login'
          )
          window.location.href = destination
        })

    },
    clearError() {
      this.error = defaultData().error
    },
    reset() {
      _.assign(this, defaultData())
    },
    forceLogin(){
      console.log('forceLogin ')
      this.$cookies.remove('auth_token')
      _.assign(this, defaultData())
      localStorage.removeItem('switchCustomerId')
      localStorage.removeItem('switchedCustomerInfo')
      // router.push({ name: "login" })
      let destination = _.get(
        router.app.$route,
        'query.redirectFrom',
        '/login'
      )
      window.location.href = destination
    },
  },
  created() {
    this.clearError()
  },
})

export default auth
