// import auth from "../_main/auth"
// import http from "../_main/http"
import router from "../_main/router"

export default {
  cart()               { router.push({ name: "cart" }) },
  customer(customer)   { router.push({ name: "customer", params: { id: customer.id } }) },
  user(user)           { router.push({ name: "user", params: { id: user.id } }) },
  customerQuote(quote) { router.push({ name: "customerQuote", params: { id: quote.id } }) },
  adminQuote(quote)    { router.push({ name: "adminQuote", params: { id: quote.id } }) },
  customerOrder(order) { router.push({ name: "customerOrder", params: { id: order.id } }) },
  adminOrder(order)    { router.push({ name: "adminOrder", params: { id: order.id } }) },
  drawing(drawing)     { router.push({ name: "drawing", params: { id: drawing.id } }) },
  // userAsset(asset) { router.push({ name: "userAsset", params: { id: asset.id } }) },
  // userAssets()         { router.push({ name: "userAssets"}) },

  userPart(user_part) { router.push({ name: "userPart", params: { id: user_part.id } }) },
  userParts()         { router.push({ name: "userParts"}) },

  userKit(user_kit) { router.push({ name: "userKit", params: { id: user_kit.id } }) },
  userKits()         { router.push({ name: "userKits"}) },

  userEquipment(user_equipment) { router.push({ name: "userEquipment", params: { id: user_equipment.id } }) },
  userEquipments()         { router.push({ name: "userEquipments"}) },

  userPump(user_pump) { router.push({ name: "userPump", params: { id: user_pump.id } }) },
  userPumps()         { router.push({ name: "userPumps"}) },

  equipment(equipment) { router.push({ name: "equipment", params: { id: equipment.id } }) },
  equipments()         { router.push({ name: "equipments"}) },

  kit(kit)             { router.push({ name: "kit", params: { id: kit.id } }) },
  location(location)   { router.push({ name: "location", params: { id: location.id } }) },
  note(note)           { router.push({ name: "note", params: { id: note.id } }) },
  country(country)     { router.push({ name: "country", params: { id: country.id } }) },
  part(part)           { router.push({ name: "part", params: { id: part.id } }) },
  pump(pump)           { router.push({ name: "pump", params: { id: pump.id } }) },
  assetTag(tag)        { router.push({ name: "assetTag", params: { id: tag.id } }) },
  manufacturer(manufacturer)           { router.push({ name: "manufacturer", params: { id: manufacturer.id } }) },
  // userPart(part)       { router.push({ name: "userPart", params: { id: part.id } }) },
  userDashboard()      { router.push({ name: "userDashboard" }) },
  pumpsCategory(manufacturer)           { router.push({ name: "pumpManufacturer", params: { id: manufacturer.id, name:manufacturer.name } }) },
  switchCustomer(customer) {
    if (this.$auth.is.ieqAdmin) {        
      this.$http.get(this.$routes.customer_path(customer.id)).then(response => {
        this.$auth.currentCustomer = response.data.customer
        localStorage.setItem('switchCustomerId', customer.id)
        localStorage.setItem('switchedCustomerInfo', JSON.stringify(response.data.customer))
        this.$navigate.userDashboard()
      })
    }
  },
}
