import axios from 'axios'
import auth from '../_main/auth'
// import { SnackbarProgrammatic as Snackbar } from 'buefy' // deepti
import { authHeader } from '../_main/_auth-header'

// const CancelToken = axios.CancelToken;
// let cancel;
const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
const http = axios.create({
  // baseURL: 'http://127.0.0.1:8000/api/',
  // baseURL: process.env.VUE_APP_API_ENDPOINT,
  responseType: 'json',
  //headers: authHeader(),
  headers: {
    ...authHeader(),
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-CSRFToken': csrftoken,
  },
  /* headers: {
    //'X-CSRF-Token': document.getElementsByName("csrf-token")[0].content,
    'X-CSRFToken': csrftoken,
    Authorization: 'Token e208a98bcb30a0dc629c3ba44dd1b592494a9493',
    //'Content-Type': 'application/json',
  }, */
})

http.interceptors.response.use(
  (primaryResponse) => {
    if (
      primaryResponse.headers.currentcustomerid &&
      primaryResponse.headers.currentcustomerid != auth.currentCustomer.id
    ) {
      console.log([
        'RELOADING CUSTOMER',
        primaryResponse.headers.currentcustomerid,
        auth.currentCustomer.id,
        auth.currentCustomer,
      ])
      // TODO: currently this customer request will fire several times due to parallel requests...ideally we find a javascript way to make sure it only fires once or maybe redirect to current route?
      // http
      //   .get(Routes.customer_path(primaryResponse.headers.currentcustomerid))
      //   .then((customerResponse) => {
      //     auth.currentCustomer = customerResponse.data.customer
      //     console.log([
      //       'CUSTOMER RELOADED',
      //       customerResponse,
      //       auth.currentCustomer,
      //     ])
      //   })
    }
    return primaryResponse
  },
  (error) => {
    if (error.response) {
      console.log(error.response)
      console.log(error.response.status)
      console.log('Unauthorized 401 from http')
      if(error.response.status == 401){ // Unauthorized 
        // auth.isSignedIn = false
        console.log('Unauthorized 401')        
        auth.forceLogin();        
      }
    }
    return Promise.reject(error)
  }
)

// http.interceptors.request.use((config) => {

//   if (cancel) {
//     cancel(); // cancel request
//   }

//   config.cancelToken =  new CancelToken(function executor(c)
//     {
//       if(!window.$cookies.isKey('auth_token')){
//         cancel = c;
//       }
//       // If  not valid, set cancel=c, else do nothing
      
//     })

//   return config

// }, function (error) {
//   return Promise.reject(error)
// });

export default http