export default {
  //A
  accept_user_invitation_path(){
    return 'auth/users/activation/'
  },
  add_part_to_library() {
    return `api/user_parts/add_to_library/`
  },
  asset_tags_path(){
    return 'api/asset_tags/'
  },
 
  asset_tag_path(id) {
    return `api/asset_tags/${id}/`
  },
  asset_tag_code_path(){
    return `api/find_asset_code/`
  },
  archive_parts_path(){
    return 'api/parts/update_archive/'
  },
  archive_kits_path() {
    return 'api/kits/update_archive/'
  },
  archive_equipments_path() {
    return 'api/equipments/update_archive/'
  },
  archive_pumps_path(){
    return 'api/pumps/update_archive/'
  },
  archive_drawings_path(){
    return 'api/drawings/update_archive/'
  },
  archive_asset_tags_path(){
    return 'api/asset_tags/update_archive/'
  },
  archive_user_parts_path(){
    return 'api/user_parts/update_archive/'
  },
  archive_user_kits_path(){
    return 'api/user_kits/update_archive/'
  },
  archive_user_pump_path(){
    return 'api/user_pumps/update_archive/'
  },
  archive_user_equipment_path(){
    return 'api/user_equipments/update_archive/'
  },
  // attachment_preview_img_path(){
  //   return 'api/get_preview_base64_img/'
  // },
  //C
  clone_record_path(){
    return 'api/clone_record/'
  },
  countries_path() {
    return 'api/countries/'
  },
  country_path(id) {
    return `api/countries/${id}/`
  },
  customers_path() {
    return 'api/customers/'
  },
  customer_path(id) {
    return `api/customers/${id}/`
  },
  customer_dashboard_path() {
    return 'api/customer_dashboard/'
  },
  customer_quotes_path(){
    return 'api/customer_quotes/'
  },
  customer_quote_path(id) { // For customer
    return `api/customer_quotes/${id}/`
  },
  all_customer_submitted_quotes_path(){
    return 'api/customer_quotes/list_submitted_quotes/'
  },
  // get_customer_quote_path(id){ // For admin
  //   return `api/customer_quotes/get_customer_quote/${id}/`
  // },
  customer_orders_path(){
    return 'api/customer_orders/'
  },
  customer_order_path(id) {
    return `api/customer_orders/${id}/`
  },
  create_library_customer_path(id) {
    return `api/customers/${id}/create_library/` // customer id
  },
  create_bulk_parts_path() {
    return 'api/parts/create_bulk/'
  },
  //D
  drawings_path() {
    return 'api/drawings/'
  },
  drawing_path(id) {
    return `api/drawings/${id}/`
  },
  drawings_base_list_path(){
    return 'api/drawings_base_list/'
  },
  //E
  
  equipments_path() {
    return 'api/equipments/'
  },
  equipment_path(id) {
    return `api/equipments/${id}/`
  },
  equipments_base_list_path(){
    return 'api/equipments_base_list/'
  },
  export_user_pumps_path(){
    return 'api/export_csv/'
  },
  //F
  families_path(){
    return 'api/families/'
  },
  findByNumber_parts() {
    return 'api/parts/findByNumber/'
  },
  
  //G
 
  get_all_assigned_asset_tags_path(){
    return 'api/asset_tags/get_all_assigned_asset_tags/'
  },
  get_all_unassigned_asset_tags_path(){
    return 'api/asset_tags/get_all_unassigned_asset_tags/'
  },
  generate_hotspots_drawings_path() {
    return 'api/drawings/generate_hotspots/'
  },
  get_used_in_buckets_with_count_path(){
    return 'api/get_used_in_buckets_with_count/'
  },
  getPumpDataFromCatalog_path(){
    return 'api/pumps/get_pump_data_from_catalog/'
  },
  //I
  init_path() {
    return 'api/init/'
  },
  invite_user_path(){
    //return 'auth/users/resend_activation/'
    return 'api/users/send_activation_email/'
  },
  //K
  kits_path() {
    return 'api/kits/'
  },
  kit_path(id) {
    return `api/kits/${id}/`
  },
  //invite_user_path(user) {},
  //M
  manufacturers_deep_path(){
    return 'api/manufacturer/'
  },
  manufacturers_path() {
    return 'api/manufacturers/'
  },
  manufacturer_path(id) {
    return `api/manufacturers/${id}/`
  },
  maintenance_frequencies_path(){
    return 'api/maintenance_frequencies/'
  },
  //N
  notes_path() {
    return 'api/notes/'
  },
  note_path(id) {
    return `api/notes/${id}/`
  },
  //P
  parts_path() {
    return 'api/parts/'
  },
  part_path(id) {
    return `api/parts/${id}/`
  },
  pumps_path() {
    return 'api/pumps/'
  },
  pump_path(id) {
    return `api/pumps/${id}/`
  },
  pump_base_list() {
    return 'api/pump_base_list/'
  },
  pump_families_path(){
    return 'api/pump_families/'
  },
  pump_parts_path(){
    return 'api/pump_parts/'
  },
  pump_part_search_path(){
    return 'api/pumps/pump_part_search/'
  },

  //R  
  recommended_items_path(){
    return 'api/parts/'
  },
  recommended_user_parts_path(){
    return 'api/user_parts/'
  },
  reset_password_path(){
    return 'auth/users/reset_password/' // django
  },
  reset_password_confirm_path(){
    return 'auth/users/reset_password_confirm/' // django
  },
  //S
  set_password_path(){
    return 'auth/users/set_password/' // django
  },
  states_path(){
    return 'api/states/'
  },
  state_path(id) {
    return `api/states/${id}/`
  },

  search_path() {
    return 'api/search/'
  },
  //U
  uploads_path(){
    return 'api/uploads/'
  },
  upload_attachments_path(){
    return 'api/attachments/'
  },
  delete_attachment_path(id) {
    return `api/attachments/${id}/`
  },
  users_path() {
    return 'api/users/'
  },
  user_path(id) {
    return `api/users/${id}/`
  },

  user_parts_path() {
    return 'api/user_parts/'
  },
  user_part_path(id) {
    return `api/user_parts/${id}/`
  },

  user_kits_path() {
    return 'api/user_kits/'
  },
  user_kit_path(id) {
    return `api/user_kits/${id}/`
  },

  user_equipments_path() {
    return 'api/user_equipments/'
  },
  user_equipment_path(id) {
    return `api/user_equipments/${id}/`
  },

  user_pumps_path() {
    return 'api/user_pumps/'
  },
  user_pump_path(id) {
    return `api/user_pumps/${id}/`
  },

  user_assets_path() {
    return 'api/user_pumps/'
  },
  // user_asset_path(id) {
  //   return `api/user_assets/${id}/`
  // },
  export_user_equipments_path(){ // Need to create this path
    return
  },
  user_locations_path() {
    return 'api/user_locations/'
  },
  user_location_path(id) {
    return `api/user_locations/${id}/`
  },
  
  // user_drawing_path(id) {
  //   // Not in sheet
  // },

}