<template lang="pug">
  div#attachmentDisplayModal
    b-modal.attachmentDialog(
      :active.sync="$attachmentDisplayModal.display"
      :hasModalCard="true"
    )
      //- :canCancel="['escape', 'outside']"
      div.modal-card
        header.modal-card-head
          p.modal-card-title {{ $attachmentDisplayModal.attachment.name }}
        section.modal-card-body
          figure.image
            img(:src="$attachmentDisplayModal.attachment.file")
        footer.modal-card-foot.has-content-justified
          div.level-left
            //- app-button.is-danger(v-if="$auth.is.ieqAdmin") Delete
            a.button.is-save(:href="$attachmentDisplayModal.attachment.file") Download
          div.level-right
            a.button.level-item(@click="$attachmentDisplayModal.previous" :disabled="!$attachmentDisplayModal.hasPreviousAttachment"): b-icon(icon="skip-previous")
            a.button.level-item(@click="$attachmentDisplayModal.next" :disabled="!$attachmentDisplayModal.hasNextAttachment"): b-icon(icon="skip-next")
</template>
<script>
  import Vue from 'vue'
  function defaultData() {
    return {
      display: false,
      attachments: [],
      attachment: {}
    }
  }

  Vue.prototype.$attachmentDisplayModal = new Vue({
    data() {
      return defaultData()
    },
    computed: {
      currentAttachmentIndex() {
        console.log("this.attachments ", this.attachments);
        return _.indexOf(this.attachments, this.attachment)
      },
      lastAttachmentIndex() {
        return this.attachments.length - 1
      },
      nextAttachmentIndex() {
        return this.currentAttachmentIndex == this.lastAttachmentIndex ? null : this.currentAttachmentIndex + 1
      },
      hasNextAttachment() {
        return !_.isNull(this.nextAttachmentIndex)
      },
      previousAttachmentIndex() {
        return this.currentAttachmentIndex == 0 ? null : this.currentAttachmentIndex - 1
      },
      hasPreviousAttachment() {
        return !_.isNull(this.previousAttachmentIndex)
      },
    },
    methods: {
      show(attachment, attachments=[]) {
        this.display = true
        this.attachments = attachments
        this.attachment = attachment
      },
      next() {
        if(this.hasNextAttachment) {
          this.attachment = this.attachments[this.nextAttachmentIndex]
        }
      },
      previous() {
        if(this.hasPreviousAttachment) {
          this.attachment = this.attachments[this.previousAttachmentIndex]
        }
      }
    }
  })

  export default Vue.prototype.$attachmentDisplayModal
</script>

<style lang="scss" scoped>
  .modal-card {
    width: 960px;
  }
</style>
