import Router from 'vue-router'
import auth from '@/_main/auth'

import NProgress from 'nprogress'

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      name: 'root',
      path: '/',
      component: () => import('../views/UserDashboard'),
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('../views/Login'),
    },
    {
      name: 'profile',
      path: '/profile',
      component: () => import('../views/UserProfile'),
      props: { id: _.get(auth.currentUser, 'id') },
    },

    {
      name: 'acceptInivation',
      path: '/invitation/accept',
      // path: "/set_password",
      // path: '/auth/activate/:uid/:token',
      component: () => import('../views/AcceptInivation'),
      meta: { authNotRequired: true },
    },

    {
      name: 'forget_password',
      path: '/forget_password',
      component: () => import('../views/ForgetPassword'),
      meta: { authNotRequired: true },
    },

    {
      name: 'set_password',
      //path: '/setpassword/:user_id',
      // path: "/set_password",
      path: '/setpassword/:uid/:token',
      component: () => import('../views/ResetPassword'),
      meta: { authNotRequired: true },
    },

    {
      name: 'change_password',
      path: '/change_password',
      component: () => import('../views/ChangePassword'),
    },

    {
      name: 'contact',
      path: '/contact',
      component: () => import('../views/Contact'),
    },
    { 
      name: 'assetTagCode', 
      path: '/asset_tag/:code', 
      component: () => import('../views/AssetTagCode'), 
      props: true},
    {
      name: 'map',
      path: '/map',
      component: () => import('../components/GoogleMap'),
    },

    {
      name: 'admin',
      path: '/admin',
      component: () => import('../components/Folder'),
      beforeEnter: adminGuard,
      children: [
        {
          name: 'users',
          path: 'users',
          component: () => import('../views/Users'),
        },
        {
          name: 'user',
          path: 'user/:id',
          component: () => import('../views/UserProfile'),
          props: true,
        },
        {
          name: 'customers',
          path: 'customers',
          component: () => import('../views/Customers'),
        },
        {
          name: 'customer',
          path: 'customer/:id',
          component: () => import('../views/Customer'),
          props: true,
        },

        {
          name: 'countries',
          path: 'countries',
          component: () => import('../views/Countries'),
        },
        // { name: 'newCountry',  path: 'countries/new',    component: ()=> import('../views/Country'), props: {id: 'new'}},
        // { name: 'Country',    path: 'country/:id', component: ()=> import('../views/Country'),   props: true},

        {
          name: 'manufacturers',
          path: 'manufacturers',
          component: () => import('../views/Manufacturers'),
        },
        {
          name: 'newManufacturer',
          path: 'manufacturers/new',
          component: () => import('../views/Manufacturer'),
          props: { id: 'new' },
        },
        {
          name: 'manufacturer',
          path: 'manufacturer/:id',
          component: () => import('../views/Manufacturer'),
          props: true,
        },

        {
          name: 'adminQuotes',
          path: 'quotes',
          component: () => import('../views/AdminQuotes'),
          // props: { isAdm: true },
        },
        {
          name: 'adminQuote',
          path: 'quote/:id',
          component: () => import('../views/AdminQuote'),
          props: true,
        },

        {
          name: 'adminOrders',
          path: 'orders',
          component: () => import('../views/AdminOrders'),
        },
        {
          name: 'adminOrder',
          path: 'order/:id',
          component: () => import('../views/AdminOrder'),
        },
        {
          name: 'assetTags',
          path: 'asset_tags',
          component: () => import('../views/AssetTags'),
        },
        {
          name: 'assetTag',
          path: 'asset_tag/:id',
          component: () => import('../views/AssetTag'),
          props: true,
        },
      ],
    },

    {
      name: 'catalog',
      path: '/catalog',
      component: () => import('../components/Folder'),
      beforeEnter: canViewCatalog,
      children: [
        {
          name: 'cart',
          path: 'cart',
          component: () => import('../views/Cart'),
          beforeEnter: canOrder,
        },
        {
          name: 'rfq',
          path: 'rfq',
          component: () => import('../views/Cart'),
          props: { status: 'rfq' },
          beforeEnter: canOrder,
        },
        {
          name: 'order',
          path: 'order',
          component: () => import('../views/Cart'),
          props: { status: 'order' },
          beforeEnter: canOrder,
        },

        {
          name: 'drawings',
          path: 'drawings',
          component: () => import('../views/Drawings'),
        },
        {
          name: 'drawing',
          path: 'drawing/:id',
          component: () => import('../views/Drawing'),
          props: true,
        },

        {
          name: 'kits',
          path: 'kits',
          component: () => import('../views/Kits'),
        },
        {
          name: 'kit',
          path: 'kit/:id',
          component: () => import('../views/Kit'),
          props: true,
        },

        {
          name: 'parts',
          path: 'parts',
          component: () => import('../views/Parts'),
        },
        {
          name: 'part',
          path: 'part/:id',
          component: () => import('../views/Part'),
          props: true,
        },

        {
          name: 'pumps',
          path: 'pumps',
          component: () => import('../views/Pumps'),
        },
        {
          name: 'pumpManufacturer',
          path: 'pumps/manufacturer/:id/:name',
          component: () => import('../views/PumpManufacturer'),
          props: true,
        },
        // {
        //   name: 'newPump',
        //   path: 'pump/new',
        //   component: () => import('../views/Pump'),
        //   props: { id: 'new' },
        // },
        {
          name: 'pump',
          path: 'pump/:id',
          component: () => import('../views/Pump'),
          props: true,
        },
        //Deepti
        {
          name: 'equipments',
          path: 'equipments',
          component: () => import('../views/Equipments'),
        },
        //Deepti
        {
          name: 'equipment',
          path: 'equipment/:id',
          component: () => import('../views/Equipment'),
          props: true,
        },
      ],
    },
    {
      name: 'rfqConfirmation',
      path: '/confirmation/rfq',
      component: () => import('../views/Cart'),
      props: { status: 'confirmation' },
    },

    {
      name: 'library',
      path: '/library',
      component: () => import('../components/Folder'),
      beforeEnter: canViewLibrary,
      children: [
        {
          name: 'userDashboard',
          path: 'dashboard',
          component: () => import('../views/UserDashboard'),
        },

        {
          name: 'userLocations',
          path: 'locations',
          component: () => import('../views/UserLocations'),
        },
        {
          name: 'location',
          path: 'location/:id',
          component: () => import('../views/UserLocation'),
          props: true,
        },

        {
          name: 'userAssets',
          path: 'assets',
          component: () => import('../views/UserAssets'),
        },
        {
          name: 'userAsset',
          path: 'asset/:id',
          component: () => import('../views/UserAsset'),
          props: true,
        },
        //Deepti
        {
          name: 'userParts',
          path: 'parts',
          component: () => import('../views/UserParts'),
        },       
        {
          name: 'userPart',
          path: 'part/:id',
          component: () => import('../views/UserPart'),
          props: true,
        },
        {
          name: 'userKits',
          path: 'kits',
          component: () => import('../views/UserKits'),
        },       
        {
          name: 'userKit',
          path: 'kit/:id',
          component: () => import('../views/UserKit'),
          props: true,
        },
        {
          name: 'userEquipments',
          path: 'equipments',
          component: () => import('../views/UserEquipments'),
        },       
        {
          name: 'userEquipment',
          path: 'equipment/:id',
          component: () => import('../views/UserEquipment'),
          props: true,
        },
        {
          name: 'userPumps',
          path: 'pumps',
          component: () => import('../views/UserPumps'),
        },       
        {
          name: 'userPump',
          path: 'pump/:id',
          component: () => import('../views/UserPump'),
          props: true,
        },

        {
          name: 'customerQuotes',
          path: 'quotes',
          component: () => import('../views/CustomerQuotes'),
          beforeEnter: canOrder,
          // props: { isAdm: false },
        },
        {
          name: 'customerQuote',
          path: 'quote/:id',
          component: () => import('../views/CustomerQuote'),
          props: true,
          beforeEnter: canOrder,
        },

        {
          name: 'customerOrders',
          path: 'orders',
          component: () => import('../views/CustomerOrders'),
          beforeEnter: canOrder,
        },
        {
          name: 'customerOrder',
          path: 'order/:id',
          component: () => import('../views/CustomerOrder'),
          props: true,
          beforeEnter: canOrder,
        },

        {
          name: 'notes',
          path: 'notes',
          component: () => import('../views/Notes'),
        },
        {
          name: 'newNote',
          path: 'notes/new',
          component: () => import('../views/Note'),
          props: { id: 'new' },
        },
        {
          name: 'note',
          path: 'note/:id',
          component: () => import('../views/Note'),
          props: true,
        },
      ],
    },
  ],
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  console.log('auth.isSignedIn', auth.isSignedIn)
  
  if (routeFrom.name) {
    NProgress.start()
  }  
  if (routeTo.matched.some((route) => route.meta.authNotRequired)) return next()
  if (routeTo.name === 'login') return next()
  if (auth.isSignedIn) return next() 
  next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
})

// When each route is finished evaluating...
//router.afterEach((routeTo, routeFrom) => {
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

function canViewLibrary(to, from, next) {
  if (auth.can.viewLibrary) return next()
  // console.log("BLOCKING via canOrder")
  next({ name: 'userDashboard' })
}

function canViewCatalog(to, from, next) {
  if (auth.can.viewCatalog) return next()
  // console.log("BLOCKING via canViewCatalog")
  next({ name: 'userDashboard' })
}

function canOrder(to, from, next) {
  if (auth.can.order) return next()
  // console.log("BLOCKING via canViewCatalog")
  next({ name: 'userDashboard' })
}

function adminGuard(to, from, next) {
  if (auth.is.ieqAdmin || auth.is.admin) return next()
  // console.log("BLOCKING via adminGuard")
  next({ name: 'userDashboard' })
}

export default router
