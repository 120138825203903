import Vue from "vue"
import router from "../_main/router"
import auth from "../_main/auth"
import localData from '../mixins/localData'
import { authHeader } from '../_main/_auth-header'
const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value

function defaultData() {
  // console.log("auth ", auth);
  return {
    id: null,
    ref_id: null,
    asset_id: null,
    asset_name: "",
    status: "cart",
    data:{      
      full_name: _.get(auth, 'currentUser.full_name'),      
      email_address: _.get(auth, 'currentUser.email'),      
      company_name: _.get(auth, 'currentCustomer.name'),      
      reference_number: "",      
      billingLocation: {},      
      shippingLocation: {},      
      phone_number: "",      
      description: "",      
      deliveryInstructions: "",
    },    
    line_items: [],
    attachments: [],
    admin_attachments: [],
    user_id:_.get(auth, 'currentUser.id'),
    customer_id:_.get(auth, 'currentCustomer.id'),
    user:_.get(auth, 'currentUser'),
    customer:_.get(auth, 'currentCustomer'),
    // is_saved:false,
    is_submitted:false,
    is_admin_replied:false,
    is_admin_saved:false
  }
}

const cart = new Vue({
  name: 'cart',
  router: router,
  mixins: [localData],
  data() {
    return defaultData()
  },
  computed: {
    isCart() {
      return this.status === "cart"
    },
    isRFQ() {
      return this.status === "rfq"
    },
    isOrder() {
      return this.status === "order"
    },
    cartCount() {
      return _.sumBy(this.line_items, line => {
        return _.toNumber(line.qty)
      })
      // return _.sumBy(this.line_items, 'qty')
    }
  },
  created() {
    this.ref_id = this.uuidv4()
    this.uid = this.numeric_uuid()
    this.data.full_name = _.get(auth, 'currentUser.first_name') + ' '+ _.get(auth, 'currentUser.last_name')      
    this.data.email_address = _.get(auth, 'currentUser.email')      
    this.data.company_name = _.get(auth, 'currentCustomer.name')
    this.user_id =_.get(auth, 'currentUser.id')
    this.customer_id =_.get(auth, 'currentCustomer.id')
    this.user =_.get(auth, 'currentUser')
    this.customer =_.get(auth, 'currentCustomer')
  },
  // mounted() {
  //   this.ref_id = this.uuidv4()
  // },
  methods: {
    lUUID(){
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = (dt + Math.random()*16)%16 | 0;
          dt = Math.floor(dt/16);
          return (c=='x' ? r :(r&0x3|0x8)).toString(16);
      });      
      return uuid;
    },
    numeric_uuid(){
      return Math.floor(Math.random()*1E16);
    }, 
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    addItem(newLineItem) { // DWW: CALLED LOCALLY
      this.$emit("addItem", newLineItem)
      let index = _.findIndex(this.line_items, lineItem => {
        return lineItem.item.id === newLineItem.item.id
      })

      if (index >= 0) {
        this.line_items[index].qty++
        Vue.set(this.line_items, index, this.line_items[index])
      } else {
        this.line_items.push(newLineItem)
      }
    },
    addNewItem() { // DWW: CALLED FROM CART VIEW
      this.$emit("addNewITem")
      this.addItem({ qty: 1, item: {} })
    },
    addCartItem(item) { // DWW: CALLED FROM SEVERAL VIEWS
      console.log("item ", item);
      this.$emit("addCartItem", item)
      item.qty = item.qty == undefined ? 1 : item.qty;
      // item.qty = item.itemType == "Part" ? 1 : item.qty;
      
      this.addItem({ qty: item.qty, item: item })
      this.$buefy.snackbar.open({
        message: `${item.title} added to cart`,
        queue: false,
        // indefinite: true,
        actionText: "View Cart",
        onAction: () => {
          this.$navigate.cart()
        }
      })
    },
    removeCartItem(item) {
      let index = _.findIndex(this.line_items, lineItem => {
        return lineItem.item.id === item.id
      })
      this.line_items.splice(index, 1);
    },
    updateCartItem(lineItem, update) { // DWW: NOT USED
      Vue.set(this.line_items, _.indexOf(this.line_items, lineItem), update)
    },
    clearCart() { // DWW: CALLED LOCALLY
      _.assign(this, defaultData())
      this.$cart.attachments=[] // This is important to reset attachments
      this.$navigate.cart()
    },
    getHeaders(){
      return {
        headers: {
          ...authHeader(),
          'X-CSRFToken': csrftoken,
          'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        }}

    },
    getFormData(quote){
      let formData = new FormData()
      let formDataArray= [];
      formDataArray = _.cloneDeep(quote)
      console.log(formDataArray) 
      Object.entries(formDataArray).forEach(([key, value]) => {
        // if( key == 'attachments'){ 
        //   quote.attachments.forEach(function (attachment, index) {
        //     if(attachment.id)
        //   });       
        //   for( var i = 0; i < quote.attachments.length; i++ ){
            
        //     let file = quote.attachments[i];
        //     console.log(file.name)               
        //     formData.append('attachments[' + i + ']', file);
        //   }          
        // }
        if(typeof value === 'object'){  //-&& key!=='attachments'
          console.log('Type object',key)
          console.log('Type object-', typeof value );
          value = JSON.stringify(value);
          formData.append(key, value);
        }else { //-if(key!=='attachments')
          console.log('Type not object',key)
          console.log('Type not object-', typeof value );
          formData.append(key, value);
        }
          
      });
      return formData
    },
    submitQuote(quote) {
      quote.is_submitted = true;
      let formData = this.getFormData(quote)
      if(quote.id==null){
        this.$http
        .post(this.$routes.customer_quotes_path(), formData, this.getHeaders() )
        .then(response => {
          var ref = _.get(response, "data.quote.ref_id")
          if (ref) {
            this.clearCart()            
            // this.$navigate.customerQuote({ id: ref })
            this.$router.push({name: 'customerQuotes'});
          }
        })
      }else{
        this.$http
        .put(this.$routes.customer_quote_path(quote.id), formData, this.getHeaders() )
        .then(response => {
          var ref = _.get(response, "data.quote.ref_id")
          if (ref) {
            this.clearCart()
            // this.$navigate.customerQuote({ id: ref })
            this.$router.push({name: 'customerQuotes'});
          }
        })
      }
      
    },
    
    saveQuote(quote) {
      let formData = this.getFormData(quote) 
      console.log(quote)    ;
      if(quote.id==null){        
        this.$http.post(this.$routes.customer_quotes_path(), formData, this.getHeaders() )
        .then(response => {
          this.inEditMode = false;          
          this.$buefy.toast.open("Record Saved!");          
          if(this.$cart.isRFQ){
            this.clearCart();
            this.$router.push({name: 'customerQuotes'});
          }         
        }).catch(error=>{
          console.log("error --> ", error)
        })

      }else{
        
        this.$http.put(this.$routes.customer_quote_path(quote.id), formData, this.getHeaders())
        .then(response => {
          this.inEditMode = false;          
          this.$buefy.toast.open("Record Saved!");          
          if(this.$cart.isRFQ){
            this.clearCart();
            this.$router.push({name: 'customerQuotes'});
          }         
        }).catch(error=>{
          console.log("error --> ", error)
        })
      }
      
    },
    submitOrder() {
      let formData = this.getFormData(this.$data)
      this.$http
        .post(this.$routes.customer_orders_path(), formData, this.getHeaders())
        .then(response => {
          var ref = _.get(response, "data.order.ref_id")
          if (ref) {
            this.clearCart()
            // this.$navigate.customerOrder({ id: ref })
            this.$router.push({name: 'customerOrders'});
          }
        })
    }
  },
})

export default cart
