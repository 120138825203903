import Vue from 'vue'

const features = new Vue({
  name: "features",
  data() {
    return {
      catalog: true,
      library: true,
      ordering: true,
      maintenance: false
    }
  },
});

export default features
