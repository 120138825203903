import Vue from 'vue'
import App from '../App'

import Buefy from 'buefy'
import Router from 'vue-router'
import router from '../_main/router'
import Vuelidate from 'vuelidate'
import VueXlsx from 'vue-js-xlsx'
import * as VueGoogleMaps from 'vue2-google-maps'
import vSelect from 'vue-select'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueCookies from 'vue-cookies'
import _ from 'lodash'
import $ from 'jquery'

import '@mdi/font/css/materialdesignicons.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'vue-select/dist/vue-select.css'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

require('../_main/functions')
require('../_main/components')
require('../_main/errors')
require('../styles/application.scss')

Vue.component('v-select', vSelect)

Vue.use(VueCookies)
Vue.use(Buefy)
Vue.use(Router)
Vue.use(Vuelidate)
Vue.use(require('vue-moment'))
Vue.use(VueXlsx)
Vue.use(PerfectScrollbar)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAWiztSyoDT4jZn8-SZh2DiORDvnlVUG1c',// cxi.django@gmail.com -  Studio@11 //'AIzaSyANuJJvdRQhdeD_tK0gdvJjumqavt6Rtzo',
    libraries: 'places', // necessary for places input     
  },
})

Vue.prototype.$auth = require('../_main/auth').default
Vue.prototype.$cart = require('../_main/cart').default
Vue.prototype.$features = require('../_main/features').default
Vue.prototype.$http = require('../_main/http').default
Vue.prototype.$navigate = require('../_main/navigate').default
Vue.prototype.$state = require('../_main/state').default
Vue.prototype.$routes = require('../_main/_routes').default
// Vue.prototype.$routes = Routes // comes from JsRoutes and is initilized in app/views/application/index.html.slim

if ($('#app').length) {
  window.app = new Vue({
    el: '#app',
    router: router,
    computed: {
      authDATA() {
        return this.$auth.$data
      },
      cartDATA() {
        return this.$cart.$data
      },
    },
    render: (h) => h(App),
  })
}
