<template lang="pug">
#app
  template(v-if='$auth.isSignedIn')
    nav.navbar(v-if='$auth.isSignedIn')
      .navbar-brand
        a.navbar-burger(
          @click='showSidebarNav()',
          :class='{ "is-active": !showNavOnMobile }'
        )
          span
          span
          span
        a.navbar-item.toggle-sidebar.is-desktop-icon-only.is-hidden-mobile(
          @click='showSidebarNav()',
          :class='{ "is-active": !showNavOnDesktop }'
        )
          b-icon(icon='menu') 
          //-backburger
        router-link.navbar-item.is-paddingless.brand-logo(
          :to='{ name: "root" }'
        )
          img(:src='require("./images/ieq-logo.png")', v-show='!darkMode')
          img(:src='require("./images/ieq-logo-white.png")', v-show='darkMode')

        a.navbar-item.toggle-topnav.is-hidden-desktop(
          @click='showSearchBar()',
          :class='{ "is-active": showSearchOnMobile }'
        )
          b-icon(icon='magnify')
        a.navbar-item.toggle-topnav.is-hidden-desktop(
          @click='showTopNavOnMobile = !showTopNavOnMobile',
          :class='{ "is-active": showTopNavOnMobile }'
        )
          b-icon(icon='dots-vertical')

      b-field.header-search.is-hidden-mobile.is-hidden-tablet-only
        vue-autosuggest.search(
          v-model='searchText',
          @keyup.enter="getSearchResult(searchText)"
          :suggestions='searchDataList',
          @focus='focusMe',
          @input='onInputChange',
          @selected='onSelected',
          :section-configs='sectionConfigs',
          :get-suggestion-value='getSuggestionValue',
          :input-props='inputProps'
        )
          template(slot='before-suggestions')
            p(v-show='searchDataLoading', style='text-align: center;') Loading...
          template(slot-scope='{suggestion, index, cs}')
            div {{ suggestion && suggestion.item.text }}
          template(slot='after-suggestions')
            p(v-show='searchResultCount == 0', style='text-align: center;' ) No Results...
          

      .navbar-menu.is-hidden-desktop(
        :class='{ showSearchOnMobile: showSearchOnMobile }',
        v-if='$auth.isSignedIn'
      )
        .navbar-item
          b-field.header-search
            //- b-input.search(placeholder="Search Assets and Locations" expanded rounded icon="magnify")

      .navbar-menu(
        :class='{ showTopNavOnMobile: showTopNavOnMobile }',
        v-if='$auth.isSignedIn'
      )
        .navbar-end
          .navbar-item(v-if='$auth.can.order')
            router-link.button.is-white(:to='{ name: "cart" }')
              b-icon.badge.has-badge-black.has-badge-left.has-badge-outlined.has-badge-rounded(
                icon='cart',
                :data-badge='$cart.cartCount'
              )
              span.is-hidden-mobile Cart
          .navbar-item.is-hidden-mobile(v-if='$auth.isSignedIn')
            button.button.is-white(
              @click='$router.push({ name: "userDashboard" })'
            )
              b-icon.is-marginless(
                v-if='!$auth.currentCustomer.logoUrl',
                icon='office-building'
              )
              img.image(
                v-if='$auth.currentCustomer.logoUrl',
                :src='$auth.currentCustomer.logoUrl',
                :style='{ height: "1rem", marginRight: "0.1875rem" }'
              )
              span.is-hidden-mobile {{ $auth.currentCustomer.name }}
          b-dropdown.navbar-item(
            v-if='$auth.isSignedIn',
            position='is-bottom-left',
            :mobile-modal='false'
          )
            button.button.is-white(slot='trigger')
              b-icon(icon='account')
              span.is-hidden-mobile {{ $auth.currentUser.name || $auth.currentUser.email }}
              b-icon(icon='menu-down')

            b-dropdown-item(@click='$auth.logOut')
              b-icon(icon='logout')
              span Log Out

            b-dropdown-item(@click='$router.push({ name: "profile" })')
              b-icon(icon='account')
              span Profile
              
            b-dropdown-item(@click='$router.push({ name: "change_password" })')
              b-icon(icon='account-key')
              span Change Password

            .dropdown-item(v-if='$auth.is.ieqAdmin')
              b-checkbox(v-model='$features.catalog') Catalog?

            .dropdown-item(v-if='$auth.is.ieqAdmin')
              b-checkbox(v-model='$features.library') Library?

            .dropdown-item(v-if='$auth.is.ieqAdmin')
              b-checkbox(v-model='$features.ordering') Orders?

            .dropdown-item(v-if='$auth.is.ieqAdmin')
              b-checkbox(v-model='$features.maintenance') Maintenance?
              //- For dark theme
            .dropdown-item
              b-field.theme-switch-wrap(size='is-small')
                b-switch#theme-switch.theme-switch(
                  v-model='darkMode',
                  type='is-primary'
                ) Switch to Dark Theme
              //- end for dark theme

          .navbar-item(v-if='!$auth.isSignedIn')
            .buttons
              app-button(
                icon='login-variant',
                @click='$router.push({ name: "login" })'
              ) Log In

          .navbar-item
            router-link.button.is-finalize(:to='{ name: "contact" }')
              b-icon(icon='forum')
              span.is-hidden-mobile Contact IEQ
    aside.menu.is-narrow.sidebar(
      :class='[{ "is-active": showMainSidebar }]',
      v-if='$auth.isSignedIn'
    )
      a.navbar-item.toggle-sidebar.is-desktop-icon-only.is-hidden-mobile(
        @click='showSidebarNav()',
        :class='{ "is-active": !showNavOnDesktop }'
      )
        b-icon(icon='menu') 
      perfect-scrollbar
        .account(v-if='$auth.can.viewLibrary') 
          p.menu-label Your Library
          ul.menu-list
            li(v-if='$auth.currentCustomer.logoUrl')
              router-link.is-paddingless(:to='{ name: "userDashboard" }')
                img.image(
                  :src='$auth.currentCustomer.logoUrl',
                  :style='{ height: "75px", maxWidth: "100px", marginLeft: "0.6rem" }'
                )
            li: router-link(
              :to='{ name: "userDashboard" }',
              :class='{ "is-active": $router.currentRoute.name == "userDashboard" || $router.currentRoute.name == "root" }'
            ) 
              b-icon(icon='home') 
              span Home
            //- li: router-link(
            //-   :to='{ name: "userAssets" }',
            //-   :class='{ "is-active": $router.currentRoute.name == "userAssets" || $router.currentRoute.name == "userAsset" }'
            //- ) 
            //-   b-icon(icon='folder-table') 
            //-   span Assets
            li: router-link(
              :to='{ name: "userPumps" }',
              :class='{ "is-active": $router.currentRoute.name == "userPumps" || $router.currentRoute.name == "userPump" }'
            ) 
              b-icon(icon='engine-outline') 
              span Pumps

            li: router-link(
              :to='{ name: "userEquipments" }',
              :class='{ "is-active": $router.currentRoute.name == "userEquipments" || $router.currentRoute.name == "userEquipment" }'
            ) 
              b-icon(icon='folder-table') 
              span Equipments
            
            li: router-link(
              :to='{ name: "userLocations" }',
              :class='{ "is-active": $router.currentRoute.name == "userLocations" || $router.currentRoute.name == "location" }'
            ) 
              b-icon(icon='map-marker-radius') 
              span Locations

            li: router-link(
              :to='{ name: "userParts" }',
              :class='{ "is-active": $router.currentRoute.name == "userParts" || $router.currentRoute.name == "userPart" }'
            ) 
              b-icon(icon='pipe-disconnected') 
              span Parts
            
            li: router-link(
              :to='{ name: "userKits" }',
              :class='{ "is-active": $router.currentRoute.name == "userKits" || $router.currentRoute.name == "userKit" }'
            ) 
              b-icon(icon='briefcase-check') 
              span Kits
              
            //- Commented on request of client per -  https://app.clubhouse.io/ieq/story/1149/cxi-new-app-changes
            //- li(v-if='$auth.can.order')
            //-   router-link(
            //-     :to='{ name: "customerQuotes" }',
            //-     :class='{ "is-active": $router.currentRoute.name == "customerQuotes" || $router.currentRoute.name == "customerQuote" }'
            //-   )
            //-     b-icon(icon='book-open') 
            //-     span Quotes
            //- li(v-if='$auth.can.order')
            //-   router-link(
            //-     :to='{ name: "customerOrders" }',
            //-     :class='{ "is-active": $router.currentRoute.name == "customerOrders" || $router.currentRoute.name == "customerOrder" }'
            //-   )
            //-     b-icon(icon='truck') 
            //-     span Orders
            //- li(v-if='$auth.can.order')
            //-   router-link(
            //-     :to='{ name: "cart" }',
            //-     :class='{ "is-active": $router.currentRoute.name == "cart" || $router.currentRoute.name == "rfq" }'
            //-   ) 
            //-     b-icon.badge.has-badge-black.has-badge-outlined.is-badge-small.has-badge-rounded(
            //-       icon='cart',
            //-       :data-badge='$cart.cartCount'
            //-     )
            //-     span Cart

        template(v-if='$auth.can.viewCatalog')
          p.menu-label Catalog
          ul.menu-list.catalog
            li: router-link(
              :to='{ name: "pumps" }',
              :class='{ "is-active": $router.currentRoute.name == "pumps" || $router.currentRoute.name == "pump" }'
            ) 
              b-icon(icon='engine-outline') 
              span Pumps
            li: router-link(
              :to='{ name: "parts" }',
              :class='{ "is-active": $router.currentRoute.name == "parts" || $router.currentRoute.name == "part" }'
            ) 
              b-icon(icon='pipe-disconnected') 
              span Parts
              
            li: router-link(
              :to='{ name: "drawings" }',
              :class='{ "is-active": $router.currentRoute.name == "drawings" || $router.currentRoute.name == "drawing" }'
            ) 
              b-icon(icon='nfc-search-variant') 
              span Drawings
            li: router-link(
              :to='{ name: "kits" }',
              :class='{ "is-active": $router.currentRoute.name == "kits" || $router.currentRoute.name == "kit" }'
            )
              b-icon(icon='briefcase-check') 
              span Kits

            //- Commented on request of client per -  https://app.clubhouse.io/ieq/story/1149/cxi-new-app-changes  
            //- li: router-link(
            //-   :to='{ name: "equipments" }',
            //-   :class='{ "is-active": $router.currentRoute.name == "equipments" || $router.currentRoute.name == "equipments" }'
            //- ) 
            //-   b-icon(icon='folder-table') 
            //-   span Equipments
        template(v-if="$auth.isTrialUser")
          p.menu-label Catalog
          ul.menu-list.catalog
            
            a.disabled(title="Upgrade to view Catalog") 
              b-icon(icon='engine-outline') 
              span Pumps
            a.disabled(title="Upgrade to view Catalog")  
              b-icon(icon='pipe-disconnected') 
              span Parts
              
            a.disabled(title="Upgrade to view Catalog") 
              b-icon(icon='nfc-search-variant') 
              span Drawings
            a.disabled(title="Upgrade to view Catalog") 
              b-icon(icon='briefcase-check') 
              span Kits

        template(v-if='$auth.is.ieqAdmin')
          p.menu-label Admin
          ul.menu-list
            li: router-link(
              :to='{ name: "customers" }',
              :class='{ "is-active": $router.currentRoute.name == "customers" || $router.currentRoute.name == "customer" }'
            )
              b-icon(icon='account-star') 
              span Customers
            //- mdi-account-star
            li: router-link(
              :to='{ name: "users" }',
              :class='{ "is-active": $router.currentRoute.name == "users" || $router.currentRoute.name == "user" }'
            )
              b-icon(icon='account-multiple') 
              span Users
            //- mdi-account-multiple
            li: router-link(
              :to='{ name: "manufacturers" }',
              :class='{ "is-active": $router.currentRoute.name == "manufacturers" || $router.currentRoute.name == "manufacturer" }'
            )
              b-icon(icon='factory') 
              span Manufacturers

            li: router-link(
              :to="{name: 'assetTags'}" 
              :class="{'is-active': $router.currentRoute.name == 'assetTags' || $router.currentRoute.name == 'assetTags'}"
            )
              b-icon(icon="barcode") 
              span Asset Tags

            li(v-if='$auth.can.order')
              router-link(
                :to='{ name: "adminQuotes" }',
                :class='{ "is-active": $router.currentRoute.name == "adminQuotes" || $router.currentRoute.name == "adminQuote" }'
              )
                b-icon(icon='book-open') 
                span Quotes
            //- li: router-link(:to="{name: 'countries'}") Country/State/City
            //- li: a(href='/CXi-data')
            //-   b-icon(icon='database-plus') 
            //-   span CXi Data
            //- mdi-database-plus

        template(v-if='!$auth.is.ieqAdmin && $auth.currentUser.customerAdmin')
          p.menu-label Admin
          ul.menu-list
            li: router-link(
              :to='{ name: "customer", params: { id: $auth.currentCustomer.id } }',
              :class='{ "is-active": $router.currentRoute.name == "customer" }'
            ) Customer Detail

    .wrapper-main.cxi-watermark1(
      :class='[{ "hero is-large is-fullheight brand-gradient-1": !$auth.isSignedIn }, { "sidebar-active": showMainSidebar }]'
    )
      main.section(:class='{ "is-Login": !$auth.isSignedIn }')
        .columns
          .column
            router-view
      .fab(
        @click='showTodoListSidebar()',
        :class='{ "is-active": showTodoList }'
      )
        span Notes

      aside.todoListSidebar(
        :class='[{ "is-active": showTodoList }]',
        v-if='$auth.isSignedIn'
      )
        .todoWrapper
          .sidebarHeader
            .sidebarTitleWrap
              h6.smallTitle Notes
              h3.sidebarTitle My Notes
            //- a.undo.button.is-light.is-rounded(v-if="showUndo" @click="undoNote()")
            //-   b-icon(icon="undo")
            a.close.button.is-light.is-rounded(@click='showTodoListSidebar()')
              b-icon(icon='close')
          b-field.addToDoItemWrap
            b-input.toDoItem(
              v-model='noteName',
              placeholder='Add a note',
              expanded,
              rounded,
              icon='plus'
            )
            b-button.addToDoItem.is-primary(@click='addNote()')
              b-icon(icon='subdirectory-arrow-left')

          .listWrapper
            .todoListWrap
              ul.todoList(v-for='(note, index) in completedNotes')
                li: .todoListItemWrap
                  .todolistIcon
                    a.button.is-light.is-rounded(
                      @click='markAsComplete(note)',
                      title='Mark as complete'
                    )
                      b-icon(icon='checkbox-blank-circle-outline')
                  .todolistItem {{ note.name }}
                  .todolistAction
                    a.edit.button.is-light.is-rounded(
                      @click='editTodoItem(note)'
                    )
                      b-icon(icon='pencil')

            .completedListWrap
              b-collapse.card(:open='false', aria-id='completedTodos')
                .card-header(
                  slot='trigger',
                  role='button',
                  aria-controls='completedTodos',
                  @click='updateIsOpen()'
                )
                  p.card-header-title Completed ({{ notCompletedNotes.length }})
                  span.card-header-icon
                    b-icon(:icon='isOpen ? "chevron-down" : "chevron-up"')
                .card-content
                  ul.completedList.todoList(
                    v-for='(note, index) in notCompletedNotes'
                  )
                    li: .todoListItemWrap
                      .todolistIcon
                        b-icon(icon='check')
                      .todolistItem {{ note.name }}
                      .todolistAction
                        a.delete-item.button.is-light.is-rounded(
                          @click='confirmNoteDelete(note.id)'
                        )
                          b-icon(icon='delete')

      aside.todoEditItemWin(
        :class='[{ "is-active": showEditItemWin }]',
        v-if='$auth.isSignedIn'
      )
        .sidebarHeader
          a.back(@click='backToList()')
            b-icon(icon='arrow-left')
          .sidebarHeaderActions
            a.save.button.is-light.is-rounded(@click='saveNote()') Save
              //- b-icon(icon="save")
            a.delete-item.button.is-light.is-rounded(
              @click='confirmNoteDelete(note.id)'
            )
              b-icon(icon='delete')
            a.close.button.is-light.is-rounded(@click='closeTodoListSidebar()')
              b-icon(icon='close')
        .todoEditItemWrap
          b-field
            b-input(v-model='note.name', placeholder='Enter Title', expanded)
          b-field
            VueEditor(
              v-model='note.description',
              :editMode='true',
              label='Description'
            )

    footer.footer
      p.has-text-centered Copyright ©2019, IntelleQ Holdings, LLC. All rights reserved, <br>*Used under license from IntelleQ Holdings, LLC.

    TheAttachmentDisplayModal
  template(v-if='!$auth.isSignedIn')
    section.hero.is-large.is-fullheight.brand-gradient-1
      .hero-body.cxi-watermark.is-paddingless
        .container
          .columns.is-vcentered.is-centered
            .column.is-4
              router-view
</template>

<script>
import TheAttachmentDisplayModal from './components/TheAttachmentDisplayModal'
import NProgress from 'nprogress'
import { SnackbarProgrammatic as Snackbar } from 'buefy' // deepti
import { VueAutosuggest } from 'vue-autosuggest'

const debounce = function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export default {
  // uncomment below - Atul
  mixins: [
    // require("./mixins/Search").locations,
    // require("@/mixins/Search").assetTags,
  ], 
  components: { TheAttachmentDisplayModal, VueAutosuggest },
  data() {
    return {
      showSearchOnMobile: false,
      showNavOnMobile: false,
      showNavOnDesktop: true,
      showTopNavOnMobile: false,
      showMainSidebar: false,
      showTodoList: false,
      showEditItemWin: false,
      isOpen: false,
      notes: [],
      completedNotes: [],
      notCompletedNotes: [],
      note: {},
      noteName: '',
      showUndo: false,
      searchText: '',
      dSearchText: '',
      searchDataLoading: true,
      searchDataList: [],
      searchResultCount: 0,
      selected: '',

      sectionConfigs: {
        default: {
          limit: 4,
          ulClass: { 'data-darren': true },
          liClass: { 'elf-row': true },
        },
        Elf: {
          limit: 4,
        },
      },
      inputProps: {
        id: 'autosuggest__input',
        placeholder: 'Search Catalog and Asset items',
      },
      darkMode: false, // for dark theme
      authToken: '', //deepti
    }
  },

  methods: {
    updateIsOpen() {
      this.isOpen = !this.isOpen
    },
    showTodoListSidebar() {
      this.note = {}
      this.getNotes()
      this.showTodoList = !this.showTodoList
      // console.log(this.showTodoList);
    },
    editTodoItem(note) {
      this.showEditItemWin = !this.showEditItemWin
      // console.log("edit item clicked", note);
      this.note = note
    },
    backToList() {
      this.showEditItemWin = false
      this.note = {}
    },
    closeTodoListSidebar() {
      this.showTodoList = false
      this.showEditItemWin = false
    },
    showSearchBar() {
      if (screen.width <= 760) {
        this.showSearchOnMobile = !this.showSearchOnMobile
        console.log(this.showSearchOnMobile)
        //return true;
      } else {
        console.log(this.showSearchOnMobile)
        //return false;
      }
    },
    showSidebarNav() {
      if (screen.width <= 760) {
        this.showMainSidebar = this.showNavOnMobile
        this.showNavOnMobile = !this.showNavOnMobile
        console.log('ismobile-true')
        //return true;
      } else {
        this.showMainSidebar = this.showNavOnDesktop
        this.showNavOnDesktop = !this.showNavOnDesktop
        console.log('ismobile-false')
        //return false;
      }
      // return isMobile()? showNavOnMobile : showNavOnDesktop;
      // this.showMainSidebar = this.isMobile() ? this.showNavOnMobile : this.showNavOnDesktop;
      // if(this.isMobile()){
      //   this.showNavOnMobile = !this.showNavOnMobile;
      // }else{
      //   this.showNavOnDesktop = !this.showNavOnDesktop;
      // }
      return this.showMainSidebar
    },
    getNotes() {
      this.$http.get(this.$routes.notes_path()).then((res) => {
        console.log('Res Notes --> ', res)
        var notes = []
        notes = res.data.results
        this.completedNotes = notes.filter(function (note) {
          return note.isCompleted == false
        })
        this.notCompletedNotes = notes.filter(function (note) {
          return note.isCompleted == true
        })
        this.paginationInfo = res.data.pagination
      })
    },
    saveNote() {
      this.note.created_by = this.$auth.currentUser.id
      this.note.updated_by = this.$auth.currentUser.id

      if (this.note.id!==undefined){ // Existing Note
        this.$http
          .put(this.$routes.note_path(this.note.id), {
            ...this.note,
          })
          .then(() => {            
            this.note = {}
            this.noteName = ''
            this.getNotes()
            this.showEditItemWin = false
            Snackbar.open({
              message: `Note Saved Successfully`,
              queue: false,
            });
          })
          .catch((error) => {
            // NProgress.done()
            this.error = error.response.data.message
            console.log('error ', error.response.data)
          })
      }else{ //New Note        
        this.note.isCompleted = false // deepti
        this.$http
          .post(this.$routes.notes_path(), {
            ...this.note,
          })
          .then(() => {            
            this.note = {}
            this.noteName = ''
            this.getNotes()
            this.showEditItemWin = false
            Snackbar.open({
              message: `Nice Job! You saved a record`,
              queue: false,
            });
          })
          .catch((error) => {
            // NProgress.done()
            this.error = error.response.data.message
            console.log('error ', error.response.data)
          })
      }
      // console.log("note --> ", this.note);
      // this.note.user_id = this.$auth.currentUser.id //- Deepti
      
    },
    addNote() {
      this.note.name = this.noteName
      if (this.note.name != '') {
        this.saveNote()
      }
    },
    confirmNoteDelete(id) {
      // console.log("id ", id);
      var _this = this
      this.$buefy.dialog.confirm({
        title: 'Deleting note',
        message:
          'This will <b>remove</b> your note from your library. Are you sure?',
        confirmText: 'Delete Note',
        type: 'is-danger',
        hasIcon: true,
        onConfirm() {
          this.$http.delete(this.$routes.note_path(id)).then(() => {            
            _this.showEditItemWin = false
            _this.getNotes()
            _this.note = {}
            this.$buefy.toast.open('Record deleted!')
          })
        },
      })
    },
    markAsComplete(note) {
      note.isCompleted = true
      this.note = note
      this.saveNote()
    },
    // clickHandler(item) {
    //   // console.log("clickHandler item ", item);
    // },
    onSelected(...args) {
      const item = args[0]
      console.log('item', item)
      if (!item) {
        return
      }
      this.selected = item.item
      let itemType_id = item.item.itemType_id
      if (item.item.groupName == 'CATALOG') {
        if (itemType_id == 1) this.$navigate.part(item.item)
        if (itemType_id == 2) this.$navigate.kit(item.item)
        if (itemType_id == 3) this.$navigate.equipment(item.item)
        if (itemType_id == 4) this.$navigate.pump(item.item)
        
      } else if (item.item.groupName == 'LIBRARY') {
        // this.$navigate.equipment(item.item)
        if (itemType_id == 1) this.$navigate.userPart(item.item)
        if (itemType_id == 2) this.$navigate.userKit(item.item)
        if (itemType_id == 3) this.$navigate.userEquipment(item.item)
        if (itemType_id == 4) this.$navigate.userPump(item.item)
        
      }
    },
    onInputChange(text) {
      console.log(text)
      // this.getSearchResult(text);
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.text
    },
    focusMe(e) {
      console.log(e) // FocusEvent
    },
    getObjectForSearchResult(item,itemType_id,itemType,groupName,isAsset){
      let text = ''
      if(itemType!='') text = itemType + '- '
      if(itemType=='Pump'){
        // if(item.model) text +=  ' ' + item.model.number
        // if(item.manufacturer) text +=  ' ' + item.manufacturer.name
        if(item.name) text +=  ' ' + item.name
      } else if(itemType=='User Pump'){
        if(item.model) text +=  ' ' + item.model.number
        if(item.manufacturer) text +=  ' ' + item.manufacturer.name
        if(item.name) text +=  ' ' + item.name
      }
      else{
        if (
          item.number &&
          (item.number != '' || item.number != null) &&
          item.name != item.number
        )
          text += item.number
        if(item.manufacturer) text +=  ' ' + item.manufacturer.name
        if (item.name && (item.name != '' || item.name != null))
          text += ' ' + item.name
      }
      
      if(isAsset){
        if (
            item.location_name &&
            (item.location_name != '' || item.location_name != null)
          )
            text += ' ' + item.location_name
      }
      
      let obj = {};
      obj.id = item.id;
      obj.name = item.name;
      obj.text = text;
      obj.number = item.number;
      obj.itemType_id = itemType_id;
      obj.type = itemType;
      obj.groupName = groupName;
      if(isAsset) obj.location_name = item.location_name
      return obj;
    },
    getSearchResult(searchText = null) {
      console.log('searchText ', searchText)
      this.searchResultCount = 0
      this.searchDataList = []
      this.searchDataLoading = true
      
      var params = { 'search': searchText, 'library_id': this.$auth.currentCustomer$.id }
      new Promise((resolve) => {
        var list = this.$http
          .get(this.$routes.search_path(), {params:params})
          .then((response) => {
            // console.log('response', response.data.results)
            let apiResponseData = response.data.results;
            this.searchResultCount = apiResponseData.total_results
            var partsArray = apiResponseData.parts.map((item) => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(item,1,"Part","CATALOG",false)
            })
            var kitsArray = apiResponseData.kits.map((item) => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(item,2,"Kit","CATALOG",false)
            })
            var equipmentsArray = apiResponseData.equipments.map((item) => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(item,3,"Equipment","CATALOG",false)
            })
            var pumpsArray = apiResponseData.pumps.map((item) => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(item,4,"Pump","CATALOG",false)
            })
            var userPartsArray = apiResponseData.user_parts.map((item) => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(item,1,"User Part","LIBRARY",true)
            })
            var userKitsArray = apiResponseData.user_kits.map((item) => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(item,2,"User Kit","LIBRARY",true)
            })
            var userEquipmentsArray = apiResponseData.user_equipments.map((item) => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(item,3,"User Equipment","LIBRARY",true)
            })
            var userPumpsArray = apiResponseData.user_pumps.map((item) => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(item,4,"User Pump","LIBRARY",true)
            })
            let itemsArray = _.concat(partsArray, kitsArray, equipmentsArray, pumpsArray, userPartsArray, userKitsArray, userEquipmentsArray, userPumpsArray)
            resolve(list)        
            return itemsArray
            
          })
          .then((list) => {
            // console.log('list',list)
            // var uniq = _.uniqBy(list, 'groupName');
            // var groups  = _.map(uniq, _.partialRight(_.pick, ['groupName']))
            //let groups = [...new Set(list.map(item => item.groupName))]; // get Dynamic Group name - This is working
            // console.log('uniq groups',groups1)
            var groups = ['LIBRARY', 'CATALOG'];
            (this.searchDataList = groups.map((g) => ({
              label: g,
              name: g,
              data: list.filter((d) => d.groupName === g),
            }))),
              (this.searchDataLoading = false)
              // console.log('group list', this.searchDataList)
          })
          .catch((err) => {
            console.error(err)
          })
        //console.log('list', list)
      })
    },
  },
  mounted() {
    this.showMainSidebar = this.showSidebarNav()
    this.showSearchOnMobile = this.showSearchBar()
    // this.getNotes();
    // For Dark Theme
    // set 'app-background' class to body tag
    let bodyElement = document.body
    bodyElement.classList.add('app-background')

    // check for active theme
    let htmlElement = document.documentElement
    let theme = localStorage.getItem('theme')

    if (theme === 'dark') {
      htmlElement.setAttribute('theme', 'dark')
      this.darkMode = true
    } else {
      htmlElement.setAttribute('theme', 'light')
      this.darkMode = false
    }
  },
  created() {
    // console.log("Route Name")
    // console.log(this.$route.meta)
    // console.log("$router.history.current.path", this.$router.history.current.path)
     
    if (this.$cookies.isKey('auth_token')) {
      //this.$auth.authToken = this.$cookies.get('auth_token')      
      this.$http.get(this.$routes.init_path())
      .then((response) => {
        // console.log(response)        
        if(response.status == 200){
          _.assign(this.$auth, response.data)
          if(localStorage.getItem('switchCustomerId')!=undefined){
            // console.log('here')
            _.assign(this.$auth.currentCustomer$, JSON.parse(localStorage.getItem('switchedCustomerInfo')))            
          }
        } 
      })
      .catch((error) => {
        console.log(error)
        NProgress.done()            
          if(error.response.status == 401){ // Unauthorized
          this.$auth.isSignedIn = false
          this.$auth.forceLogin();
        }
      })
    } 
    // else if ( this.$route.name == 'acceptInivation'){
    //   console.log("authNotRequired")
    //    // do nothing
    // } else if (this.$router.history.current.path != '/invitation/accept') {
    //   this.$router.push('/login')
    // }
  },
  watch: {
    // For Dark Theme
    darkMode: function () {
      // add/remove class to/from html tag
      let htmlElement = document.documentElement
      if (this.darkMode) {
        localStorage.setItem('theme', 'dark')
        htmlElement.setAttribute('theme', 'dark')
      } else {
        localStorage.setItem('theme', 'light')
        htmlElement.setAttribute('theme', 'light')
      }
    },
    searchText: debounce(function (newVal) {
      // this.dSearchText = newVal;
      if (newVal.length > 2) {
        this.getSearchResult(newVal)
      }
    }, 600),
    // $route(to, from) {
    //   console.log("currentRoute ", this.$router.currentRoute);
    // }
  },
}
</script>

<style lang="scss">
@import 'styles/application';

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.brand-gradient-1 {
  background: #00adff;
  background: -webkit-linear-gradient(to right, #ffdd57, #00adff);
  background: linear-gradient(to right, #ffdd57, #00adff);
}

.cxi-watermark {
  background-image: url('images/cxi-logo-svg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.cxi-watermark1 {
  background-image: url('images/cxi-logo-svg1.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.navbar {
  box-shadow: 0px 2px 0px 0px hsl(0, 0%, 96%);

  .navbar-brand {
    // padding-left: 1.75rem;

    .navbar-item img {
      max-height: 3.25rem;
    }
  }
}

.wrapper-main {
  display: flex;
  position: relative;
  &.sidebar-active {
    padding-left: 80px !important;
  }
}

main.section {
  flex: 1;
  padding-top: 1rem;
  min-height: calc(100vh - 122px);
}

footer.footer {
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;
  letter-spacing: 1px;
  color: var(--app-nav-text-color);
}

.search input.input {
  border-bottom: 1px solid var(--app-input-bdr-color);
}

.control.has-icons-left .icon {
  margin-top: 5px;
  .mdi-magnify {
    color: #607d8b;
  }
}

.search input::-webkit-input-placeholder {
  color: #00adff !important;
  text-transform: capitalize;
}
.search input::-moz-placeholder {
  color: #00adff !important;
  text-transform: capitalize;
}
.search input:-ms-input-placeholder {
  color: #00adff !important;
  text-transform: capitalize;
}
.search input:-moz-placeholder {
  color: #00adff !important;
  text-transform: capitalize;
}

.field.has-addons {
  align-items: flex-end;
} // Deepti this is to align expand and search on drawing page

.field.header-search {
  width: 100%;
  border: 1px solid transparent !important;
  background: var(--app-header-search-bg);
  border-radius: 30px;
  margin-bottom: 4px;
  margin-top: 4px;

  input.input.is-rounded {
    border-bottom: none;
    &:focus {
      background-size: calc(100% - 18px) 100%, 100% 100%;
      background-image: linear-gradient(
        to top,
        #00adff 2px,
        rgba(70, 128, 255, 0) 2px
      );
    }
  }
}
// .sidebar {
//   background-color: $white;
//   z-index: 10;
// }
.fab {
  background: #00adff;
  width: 40px;
  height: 100px;
  border-radius: 10px 0 0 10px;
  text-align: center;
  color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5), 3px 3px 3px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 10;
  bottom: 40px;
  right: 0px;
  display: inline-block;
  cursor: default;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  &:hover,
  &.is-active {
    background: #f0b428;
  }

  &.is-active {
    right: -65px;
  }

  span {
    // display: inline-block;
    // transform-origin: 0 0;
    // transform: rotate(90deg);
    // position: absolute;
    // top: 11px;
    // right: -35px;
    display: block;
    text-orientation: upright;
    writing-mode: vertical-rl;
    a {
      color: #fff;
    }
  }
}

.todoWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listWrapper,
.todoListWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.listWrapper {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}

aside.todoListSidebar,
aside.todoEditItemWin {
  width: 0;
  position: absolute;
  right: -300px;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  background-color: var(--app-nav-bg-color);
  z-index: 10;
  margin-top: 10px;
  display: none;

  &.is-active {
    width: 300px;
    right: 0;
    height: calc(100vh - 80px);
    display: block;
  }

  .sidebarHeader {
    align-items: center;
    background-color: var(--app-card-bg-color);
    border-bottom: solid 1px var(--app-card-bdr-color);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 64px;
    padding: 0 6px 0 6px;
    position: relative;
    border-radius: 6px 0 0 0;

    .close {
      color: rgba(0, 0, 0, 0.54);
      font-weight: 100;
    }
    .save {
      color: rgba(0, 0, 0, 0.54);
      font-weight: 100;
    }
    .undo {
      color: rgba(230, 8, 8, 0.986);
      font-weight: 100;
    }
  }

  .button:not(.addToDoItem) {
    height: 40px;
    width: 40px;
    background: transparent;
    &:hover {
      background-color: #ecf0f6;
    }
  }
  .button.addToDoItem {
    background-color: #00adff;
    border-radius: 0;
  }
}

aside.todoEditItemWin {
  z-index: 11;
}

aside.todoListSidebar {
  .sidebarTitleWrap {
    box-flex: 1;
    flex-grow: 1;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;

    .smallTitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 6px 0 2px 0;
      color: #80868b;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }

    .sidebarTitle {
      font-weight: 500;
      color: var(--app-subtitle-color);
    }
  }
  .todoList li {
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: box-shadow, z-index;
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    line-height: 20px;
    min-height: 48px;
    position: relative;
    z-index: 0;

    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      top: -1px;
      right: 0;
      bottom: 0;
      left: 48px;
      border-style: solid;
      border-color: #e0e0e0;
      border-width: 0 0 1px;
    }

    .todoListItemWrap {
      display: flex;
      box-flex: 1;
      flex-grow: 1;
      flex-wrap: nowrap;
      width: 100%;
      box-sizing: border-box;
      transition: padding-left 200ms;
      padding-left: 0;

      .todolistIcon {
        position: relative;
        margin: 0 -2px 0 6px;
        width: 48px;
        height: 48px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .todolistItem {
        align-self: center;
        display: flex;
        flex-direction: column;
        box-flex: 1;
        flex-grow: 1;
        padding: 12px 0;
        width: 0;
        position: relative;
        font-size: 14px;
      }
      .todolistAction {
        position: relative;
        flex-shrink: 0;
        width: 48px;

        .edit.button {
          opacity: 0;
          position: absolute;
          bottom: 4px;
          left: 0;
          height: 40px;
          width: 40px;
          margin-left: -4px;
        }
      }
    }

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
        0 2px 6px 2px rgba(60, 64, 67, 0.149);
      outline: 0;
      z-index: 2000;

      &::before {
        left: 0;
        border-top-width: 1px;
        border-bottom-color: #bdbdbd;
      }

      .todolistAction .button {
        opacity: 1 !important;
      }
    }
  }
}
.completedListWrap {
  .card {
    margin-top: 20px;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    border-top: 1px solid var(--app-card-bdr-color);
    border-radius: 0;
  }

  .card-header {
    padding: 0 5px 0 16px;
    background: transparent;
    border-bottom: none;

    .card-header-title {
      font-size: 14px;
      font-weight: 500;
      color: #5f6368;
      padding: 0;
    }
    .card-header-icon {
      color: #5f6368;
    }
  }

  .card-content {
    border: none;
    padding: 0;

    ul.completedList {
      width: 100%;
      .todoListItemWrap {
        align-items: center;
      }
    }
  }
}

.theme-switch-wrap {
  .switch .control-label {
    white-space: nowrap;
  }
}

a.navbar-item.toggle-sidebar.is-desktop-icon-only {
  min-width: 80px;
  text-align: center;
  height: 61px;
  .icon {
    margin: 0;
    width: 100%;
    .mdi:before {
      font-size: 35px;
      font-weight: 100;
      color: var(--app-nav-icon-color);
    }
  }
}
@media (min-width: 768px) {
  .brand-logo {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  a.navbar-item {
    margin-left: 1px;
    color: var(--app-text-color);
  }
  .navbar-burger {
    margin-left: 0 !important;
  }
  .navbar-brand .brand-logo {
    margin: auto;
  }

  .navbar-burger:hover {
    background-color: transparent !important;
  }
  .navbar a.navbar-item.toggle-topnav:hover {
    color: var(--app-text-color);
  }

  .navbar a.navbar-item.toggle-topnav.is-active,
  .navbar-burger.is-active {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: var(--app-text-color);
  }

  .navbar-menu {
    transition: all 0.3s ease-in-out;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  .navbar-menu:not(.showTopNavOnMobile):not(.showSearchOnMobile) {
    height: 0;
    padding: 0;
    display: block !important;
  }
  .navbar-menu.is-hidden-desktop:not(.showSearchOnMobile) .navbar-item {
    display: none;
  }

  .navbar-menu.showTopNavOnMobile,
  .navbar-menu.showSearchOnMobile {
    display: block;
    background-color: var(--app-mobile-sec-nav-bg-color);
    border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
    .button.is-white:hover {
      background-color: rgba(255, 255, 255, 0.1);
      .icon {
        color: #ffffff;
      }
    }
  }
  .navbar-menu.showTopNavOnMobile .navbar-end {
    display: flex;
    justify-content: flex-end;
    visibility: visible;
  }
  .navbar-menu:not(.showTopNavOnMobile) .navbar-end {
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
  }
}
aside.menu .menu-list a.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
</style>
